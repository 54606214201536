import "./App.css";
import React, { useEffect, useState } from "react";
import api from "./api";
import CertifiedPage from "./components/CertifiedPage";
import Content from "./components/Content";
import Loader from "./components/Loader/Loader";
import Footer from "./components/Footer";
import TopBar from "./components/TopBar";

function App() {
  const [loading, setLoading] = useState(true);
  const [store, setStore] = useState(null);

  const params = window.location.search
    ? window.location.search
        .slice(1)
        .split("&")
        .map((p) => p.split("="))
        .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {})
    : {};
  const schema_name = params.s;

  const checkCertification = async () => {
    if (schema_name) {
      try {
        let res = await api.get(`${schema_name}/`);
        const data = res.data;
        if (data && data.schema_name) setStore(data);
      } catch (error) {
        console.log(error);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    checkCertification();
  }, []);

  if (loading) {
    return (
      <div className="container d-flex align-items-center justify-content-center">
        {/* Carregando... */}
        <Loader />
      </div>
    );
  } else if (!schema_name) {
    return (
      <div className="App">
        <TopBar />
        <div style={{ paddingTop: "50px" }}>
          <Content schema={schema_name} />
        </div>
        <Footer />
      </div>
    );
  }

  return (
    <div className="App">
      <CertifiedPage schema_name={schema_name} store={store} />
    </div>
  );
}

export default App;

import React from "react";
import Certificate from "./Certificate";
import Content from "./Content";
import NotCertified from "./NotCertified";
import TopBar from "./TopBar";
import Footer from "./Footer";

const CertifiedPage = (props) => {
  const { store, schema_name } = props;
  return (
    <>
      <TopBar />
      {store ? (
        <>
          <Certificate store={store} />
        </>
      ) : (
        <NotCertified schema_name={schema_name} />
      )}
      <Content />
      <Footer />
    </>
  );
};

export default CertifiedPage;

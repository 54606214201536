const NotCertified = (props) => {
    const { schema_name } = props
    return (
        <div className="background">
            <p className="store-name semi-bold">{schema_name}</p>
            <div className="certificate container">
                <h4 className="semi-bold pt-4">status</h4>
                <div className="shield">
                    <span className="status">
                        <h3 className="text-secondary">NOT FOUND</h3>
                    </span>
                </div>
            </div>
        </div>
    )
}

export default NotCertified
